<template>
<div class="container-fluid p-0 scroll">
    <div class="row">
        <div class="col-12">
            <div v-show="ocultarGlosario">
                <v-select id="btn1" class="pypCombo" placeholder="Glosario de Estudios" :options="glosario" label="Abreviacion" @input="getAbreviacion" @KeyDown="getAbreviacion"  v-model="SelectedAbreviacion">
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No se encontró el estudio de: <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                    </template>
                </v-select>
                <br>

                <div v-show="mostrarInformacion" class="alert alert-primary scroll"  style="max-height: 10rem;">
                    <button type="button" class="close"  @click="ocultar()">&times;</button>
                    <p><b>Nombre de Estudio:</b> {{NombreEstudio}} </p>
                    <p><b>Tipo de Estudio:</b>{{ tipoEstudio }}</p>
                    <p><b>Preparación:</b>{{ preparacion }}</p>
                    <p><b>Cita:</b>{{ cita }}</p>
                    <p><b>Paquete:</b>{{ paquete }}</p>
                    <p><b>Clinica:</b>{{ nombreClinica }}</p>
                </div>

                <div v-show="noRealizaEstudio" class="alert alert-danger scroll" role="alert" style="max-height: 10rem;">                
                    <button type="button" class="close"  @click="ocultar()">&times;</button>
                    <p><b>Nombre de Estudio:</b> {{NombreEstudio}} </p>
                    <p><b>Tipo de Estudio:</b>{{ tipoEstudio }}</p>
                </div>
            </div>

           <h5 class="text-center">Precios y Preparaciones</h5>
            <!-- <AutoComplete type="search" @change="getEstudios()" @input="getEstudios" v-model="SelectedSucursal" :suggestions="filtrarSucursal" @complete="buscarSucursal($event)" field="Descripcion" placeholder="Ciudad - Sucursal" :dropdown="true" class="pypAutoComplete"/> -->
            <!-- <Dropdown @change="getEstudios()" v-model="SelectedSucursal" :options="sucursales" :showClear="true" optionLabel="Descripcion" placeholder="Ciudad - Sucursal" :filter="true" /> -->
            <v-select class="pypCombo" placeholder="Ciudad - Sucursal" :options="sucursales" label="Descripcion" @input="getEstudios" v-model="SelectedSucursal">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró el estudio de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
        </div>
    </div>
    <div class="row">
        <div v-show="mostrarPaquete" class="col-12">
            <v-select class="pypCombo" placeholder="Estudio o paquete" :options="tipo" label="nombre" @input="cambiarTipo" v-model="Elegirtipo"></v-select>
        </div>
        <div v-show="noMostrarPaquete" class="col-12">
            <v-select class="pypCombo" placeholder="Estudio o paquete" :options="tipodos" label="nombre" @input="cambiarTipo" v-model="Elegirtipo"></v-select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <v-select class="pypCombo" placeholder="Seleccionar Estudio" label="Descripcion" v-model="SelectedEstudio" :options="estudios" @input="getSubEstudios()" >
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró el estudio de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
            
            
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- <Dropdown :disabled="subEstudios=='No'" v-if="Elegirtipo.id==1" @change="Cotizar()" v-model="SelectedSubEstudio" :showClear="true" :options="subEstudios" :filter="true" :filterValue="filtrazo" optionLabel="Descripcion" placeholder="Seleccionar SubEstudio" />
            <Dropdown :disabled="subEstudios=='No'" v-else @change="CotizarPack()" v-model="SelectedSubEstudioPack" :showClear="true" :options="subEstudios" :filter="true" optionLabel="Paquete" placeholder="Seleccionar SubEstudio" />
            <MultiSelect class="d-none" v-model="SelectedSubEstudio" :options="subEstudios" optionLabel="Descripcion" placeholder="Seleccionar subEstudio" :filter="true"/>
            <AutoComplete :key="key" v-if="Elegirtipo.id==1" :disabled="subEstudios=='No'" type="search" @input="Cotizar()" v-model="SelectedSubEstudio" :suggestions="filtrarSubEstudio" @complete="buscarSubEstudio($event)" field="Descripcion" placeholder="Seleccionar SubEstudio" :dropdown="true" class="pypAutoComplete"/>
            <AutoComplete :key="key" v-else :disabled="subEstudios=='No'" type="search" @input="CotizarPack()" v-model="SelectedSubEstudioPack" :suggestions="filtrarSubEstudio" @complete="buscarSubEstudioP($event)" field="Paquete" placeholder="Seleccionar Paquete" :dropdown="true" class="pypAutoComplete"/>
            <vSelect v-model="SelectedSubEstudio" label="Seleccionar SubEstudio" :options="subEstudios" /> -->
            <v-select :disabled="subEstudios=='No' || subEstudios==[] || subEstudios.length==0" class="pypCombo" placeholder="Buscar SubEstudio" v-if="Elegirtipo.id==1" :options="subEstudios" :reduce="sub => sub.Id" label="Descripcion" @input="Cotizar()" v-model="SelectedSubEstudio">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró el estudio de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
            <v-select :disabled="subEstudios=='No' || subEstudios==[] || subEstudios.length==0" class="pypCombo" placeholder="Buscar Paquete" v-else :options="subEstudios" :reduce="sub => sub.IdPaquete" label="Paquete" @input="CotizarPack()" v-model="SelectedSubEstudioPack">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró el paquete con: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
        </div>
    </div>
    <div class="row">
        <div v-if="subEstudios==[] || subEstudios.length==0 && Elegirtipo.id==1 && !errorApiSubEstudios" class="alert alert-danger alert-dismissible fade show m-1 eIndividual" role="alert">                
            
            Este estudio no se encuentra en la clínica seleccionada

        </div>
        <div v-if="subEstudios==[] || subEstudios.length==0 && Elegirtipo.id==2 && !errorApiSubEstudios" class="alert alert-danger alert-dismissible fade show m-1 eIndividual" role="alert">                
            
            Este estudio no se encuentra en ningun paquete

        </div>
        <div v-if="errorApiSubEstudios" class="alert alert-danger alert-dismissible fade show m-1 eIndividual" role="alert">                  
            Ha ocurrido un error al obtener la cotizacion, vuelve a intentarlo
        </div>
    </div>

    
    <!-- Lista cotizador -->
    <div class="card bg-light mb-3" style="max-width: 18rem;" v-if="lista_cotizar.length>0">
        <div class="card-header">
            <button v-show="supportsCB" @click="copiar(2)" class="btn rounded-circle mr-3 float-left">
                    <font-awesome-icon icon="copy" :style="{ color: 'gray' }"/>
            </button>
            <b class="float-right mr-5 mt-2" >Cotizaciones</b>
        </div>
        <div class="card-body">
        <div class="container">
            <div class="row" v-for="(item,index) in lista_cotizar" :key="index">
                <div class="col-9">
                <b>{{item.Descripcion}}</b><br>
                Precio: $ {{item.Precio}}<br>
                <span v-if="item.PrecioDescuento!=null">Precio con cita en linea: $ {{item.PrecioDescuento}}<br></span>
                </div>    
                <div class="col-3">
                    <button @click="delete_lista(index)" class="btn btn-danger rounded-circle text-danger" title="Eliminar Estudio">
                        <font-awesome-icon icon="trash"/>
                    </button>
                </div>
                <hr noshade="noshade" size="2" width="100%" />        
            </div>
        </div>
        </div>
    </div>
    <div v-if="this.cotizar_total>0 || this.cotizar_totdesc>0">
        Total: ${{this.cotizar_total}}
        <br>
        Total cita en linea: ${{this.cotizar_totdesc}}
    </div>
    <!-- fin lista cotizador -->


</div>
</template>

<script>
import asesorService from '../../services/asesor'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faCopy, faTrash
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faCopy,faTrash)
export default {
    name: 'Cotizaciones',
    components: {
        FontAwesomeIcon,
        vSelect
    },
    data() {
        return {
            clinica:null,
            options:[],
            sucursal: null,
            SelectedAbreviacion: null,
            SelectedSucursal: null,
            SelectedEstudio: null,
            SelectedSubEstudio: null,
            SelectedSubEstudioPack: null,
            glosario: require('../../assets/json/glosario.json'),
            sucursales: require('../../assets/json/sucursales.json'),
            filtrarSucursal: null,
            estudios: [],
            tipo:[{id:1, nombre:'Estudio individual'},{id:2, nombre:'Paquete'}],
            tipodos:[{id:1, nombre:'Estudio individual'}],
            subEstudios: ['Selecciona un estudio...'],
            cotizar:{Descripcion:null,Preparacion:null,Precio:null,PrecioDescuento:null},
            cotizarPack:{Paquete:null,Preparacion:null,PrecioPaquete:null,SubEstudio:[]},
            filtrarSubEstudio: null,
            Elegirtipo:{id:1, nombre:'Estudio individual'},
            listos:false,
            listosIndividual:false,
            Copiar: '',
            supportsCB:false,
            lista_cotizar:[],
            cotizar_total: 0,
            cotizar_totdesc: 0,
            errorApiSubEstudios:false,
            mostrarInformacion:false,
            noRealizaEstudio:false,
            NombreEstudio:'',
            tipoEstudio: '',
            preparacion: '',
            cita: '',
            paquete: '',
            nombreClinica: '',
            ocultarGlosario:true,
            mostrarPaquete: true,
            noMostrarPaquete: true
        }
    },
    created(){
        let valorNetwork= localStorage.getItem('Network')
        
        if(navigator.clipboard) {
            this.supportsCB = true
        }
        this.$bus.$off('limpiarRSV')
        this.$bus.$on('limpiarRSV', () => {
            this.limpiar()
        })

        if(valorNetwork == 'WAsa'){
            this.ocultarGlosario = false
            this.mostrarPaquete = true
            this.noMostrarPaquete = false
            this.SelectedSucursal={Ciudad:"San Salvador",Descripcion:"El Salvador - San Salvador",Id:228,Nombre:"El Salvador"}
            this.getEstudios()
        }else{
            this.ocultarGlosario = true
            this.mostrarPaquete = true
            this.noMostrarPaquete = false
        }
    },
    mounted(){
    },
    methods: {
        getAbreviacion(){
             
             return this.glosario.filter((glosario) => {
                if(glosario.Abreviacion == (this.SelectedAbreviacion.Abreviacion)){
                    this.NombreEstudio = (glosario.NombreEstudio)
                    this.tipoEstudio = (glosario.TipoEstudio)
             
                    if(glosario.Preparacion != undefined  && glosario.Cita != undefined  && glosario.Paquete != undefined  && glosario.Clinica != undefined ){
                        this.preparacion = (glosario.Preparacion)
                        this.cita = (glosario.Cita)
                        this.paquete = (glosario.Paquete)
                        this.nombreClinica = (glosario.Clinica)
                        this.noRealizaEstudio = false
                        this.mostrarInformacion = true
                    }else{
                        this.mostrarInformacion = false
                        this.noRealizaEstudio = true
                    }
                }  
             
            })
             
        },
         ocultar(){
             this.mostrarInformacion = false
             this.SelectedAbreviacion = null
             this.noRealizaEstudio = false
         },
        getEstudios(){
            this.cambiarSucursal()
            if(!this.SelectedSucursal){
                this.estudios = []
                return
            }
            asesorService.EstudioSuc(this.SelectedSucursal.Id!=0?this.SelectedSucursal.Id:46).then(respa => {
                this.estudios= respa.estudios_lista
                this.clinica = respa.clinica
                this.clinica.horario=this.clinica.HorarioAtencion.split('|')
                this.SelectedEstudio=null
                this.listos=false
                this.listosIndividual=false
                this.SelectedSubEstudio= null
                this.SelectedSubEstudioPack= null
                this.lista_cotizar=[]
                this.cotizar_total=0
                this.cotizar_totdesc=0
                
            })
        },
        getSubEstudios(){
            if(!this.SelectedSucursal || !this.SelectedEstudio || !this.Elegirtipo){
                if(!this.SelectedEstudio) {
                    this.subEstudios= ['Selecciona un estudio...']
                    this.SelectedEstudio=null
                    this.SelectedSubEstudio= null
                    this.SelectedSubEstudioPack= null
                }
                return 
            }
            this.subEstudios=['Cargando...'];
            asesorService.subEstudios(this.SelectedSucursal.Id!=0?this.SelectedSucursal.Id:46, this.SelectedEstudio.Id, this.Elegirtipo.id).then(respa => {
                this.errorApiSubEstudios=false;
                this.subEstudios= respa
                this.SelectedSubEstudio= null
                this.SelectedSubEstudioPack= null
                this.$gtag.event('cotizar',{
                    'event_category': 'Por Tipo de Estudio',
                    'event_label': this.SelectedEstudio.Descripcion,
                    'value': 0
                })
            }).catch(()=>{
                this.errorApiSubEstudios=true
                this.subEstudios= []
                this.SelectedEstudio=null
                this.SelectedSubEstudio= null
                this.SelectedSubEstudioPack= null
                this.$gtag.event('errores',{
                    'event_category': 'Cotizador',
                    'event_label': ('Sucursal:'+this.SelectedSucursal.Descripcion+' sin Estudios'),
                    'value': 500
                })
            })
            
        },
        Cotizar(){
            if(!this.SelectedSubEstudio){
                return
            }
            this.Copiar=''
            this.subEstudios.map(value=>{
                if(value.Id==this.SelectedSubEstudio){
                    this.cotizar= value
                    //this.cotizar.Preparacion=this.cotizar.Preparacion.toLowerCase()
                    this.listos=false
                    this.listosIndividual=false
                    this.listosIndividual=true
                    this.cotizar.Precio=this.cotizar.Precio ? parseFloat(this.cotizar.Precio).toFixed(2) : null
                    
                    if(this.SelectedEstudio.Id!=2){
                        this.cotizar.PrecioDescuento=this.cotizar.PrecioDescuento ? parseFloat(this.cotizar.PrecioDescuento).toFixed(2) : null
                    }else{
                        this.cotizar.PrecioDescuento=null
                    }
                    this.lista_cotizar.push(this.cotizar)
                    this.lista_cotizar=this.lista_cotizar.reverse()
                    this.getTotales()
                }
            });
        },
        CotizarPack(){
            if(!this.SelectedSubEstudioPack){
                return
            }
            this.Copiar=''
            this.subEstudios.map(value=>{
            if(value.IdPaquete==this.SelectedSubEstudioPack){
                this.cotizarPack= value
                //this.cotizarPack.Preparacion.toLowerCase()
                //this.cotizarPack.SubEstudio.toLowerCase()
                this.listos=false
                this.listosIndividual=false
                this.listos=true
                this.cotizarPack.PrecioPaquete=this.cotizarPack.PrecioPaquete ? parseFloat(this.cotizarPack.PrecioPaquete).toFixed(2) : null
               this.lista_cotizar.push({
                    Id: this.cotizarPack.IdPaquete,
                    Descripcion: this.cotizarPack.Paquete,
                    Precio: this.cotizarPack.PrecioPaquete,
                    PrecioDescuento: null,
                    Preparacion:this.cotizarPack.Preparacion,
                    SubEstudio:this.cotizarPack.SubEstudio
                    })
                this.lista_cotizar=this.lista_cotizar.reverse()

                this.getTotales()
                }
            })
            // asesorService.cotizarPrecios(this.SelectedSucursal.Id, this.SelectedEstudio.Id, this.SelectedSubEstudioPack, this.Elegirtipo.id).then(respa => {        
            //     this.cotizarPack= respa
            //     console.log(this.cotizar)
            //     //this.cotizarPack.Preparacion.toLowerCase()
            //     //this.cotizarPack.SubEstudio.toLowerCase()
            //     this.listos=false
            //     this.listosIndividual=false
            //     this.listos=true
            //     this.cotizarPack.PrecioPaquete=parseFloat(this.cotizarPack.PrecioPaquete).toFixed(2)
                
            // })
        },
        buscarSucursales(query) {
            
			return this.sucursales.filter((sucursal) => {
                return sucursal.Descripcion.toLowerCase().startsWith(query.toLowerCase());
                
            })            
        },
        getTotales(){
            this.cotizar_total=0
            this.cotizar_totdesc=0
            
            this.lista_cotizar.map(item=>{
                this.cotizar_total=parseFloat(this.cotizar_total)+parseFloat(item.Precio)
                this.cotizar_totdesc=parseFloat(this.cotizar_totdesc) + parseFloat((item.PrecioDescuento != null ? item.PrecioDescuento : item.Precio))

            })
        },
        buscarSucursal(event) {
            setTimeout(() => {
				this.filtrarSucursal = this.buscarSucursales(event.query)
            }, 250)
            
        },
        buscarSubEstudios(query) {
            
			return this.subEstudios.filter((subEstudio) => {
                return subEstudio.Descripcion.toLowerCase().startsWith(query.toLowerCase());
                
            })
            
        },
        buscarSubEstudio(event) {
            setTimeout(() => {
				this.filtrarSubEstudio = this.buscarSubEstudios(event.query)
            }, 250)
        },
        buscarSubEstudiosP(query) {
            
			return this.subEstudios.filter((subEstudio) => {
                return subEstudio.Paquete.toLowerCase().startsWith(query.toLowerCase());
                
            })
            
        },
        buscarSubEstudioP(event) {
            setTimeout(() => {
				this.filtrarSubEstudio = this.buscarSubEstudiosP(event.query)
            }, 250)
        },
        delete_lista(index){
            this.lista_cotizar.splice(index,1);
            this.getTotales()
        },
        limpiar(){
            this.listos=false
            this.listosIndividual=false
            this.SelectedSucursal= null
            this.SelectedEstudio= null
            this.SelectedSubEstudio= null
            this.SelectedSubEstudioPack= null
            this.subEstudios = ['Selecciona un estudio...']
            this.subEstudioPack = []
            this.lista_cotizar=[] 
            this.cotizar_total=0
            this.cotizar_totdesc=0
            this.errorApiSubEstudios=false
            this.clinica=null   
        },
        cambiarSucursal(){
            this.SelectedEstudio= null
            this.SelectedSubEstudio= null
            this.SelectedSubEstudioPack= null
            this.listos=false
            this.listosIndividual=false
            this.subEstudios = ['Selecciona un estudio...']
            this.subEstudioPack = []
            this.errorApiSubEstudios=false
            // this.lista_cotizar=null
        },
        cambiarTipo(){
            this.tipo = [{id:1, nombre:'Estudio individual'},{id:2, nombre:'Paquete'}]
            if(!this.Elegirtipo)
                this.Elegirtipo = {id:1, nombre:'Estudio individual'}
            this.SelectedEstudio= null
            this.SelectedSubEstudio= null
            this.SelectedSubEstudioPack= null
            this.listos=false
            this.listosIndividual=false
            this.subEstudios = ['Selecciona un estudio...']
            this.subEstudioPack = []
            this.errorApiSubEstudios=false
            // this.lista_cotizar=null
        },
        copiar(n){
            var preparaciones = ''
            var subEstudioPack =''
            
            if (0==n){
                for (var i = 0; i < this.cotizar.Preparacion.length; i++){
                    preparaciones += this.cotizar.Preparacion[i] + '\n'
                }
                

                this.Copiar=this.SelectedSucursal.Id!=0?`*-${this.cotizar.Descripcion}*\n*Sucursal:* ${this.clinica.Descripcion}\n*Horario:*\n ${this.clinica.horario[0]}\n*Horario de Laboratorio:*\n${this.clinica.horario.length>1?this.clinica.horario[1]:'7am a 12pm'}\n*Precio:* $${this.cotizar.Precio} 💰\n*Preparación:* \n${preparaciones}\n${this.cotizar.PrecioDescuento!=null ? '' : ''}`:`*-${this.cotizar.Descripcion}*\n*Preparación:* \n${preparaciones}\n*Precio:* $${this.cotizar.Precio} 💰\n${this.cotizar.PrecioDescuento!=null ? '' : ''}`
                
            } 
            else if(1==n)
            {               
                for (var x = 0; x < this.cotizarPack.Preparacion.length; x++){
                    preparaciones += this.cotizarPack.Preparacion[x] + '\n'
                }
                for(var y = 0; y < this.cotizarPack.SubEstudio.length; y++){
                    subEstudioPack += this.cotizarPack.SubEstudio[y]+'\n'
                }

                this.Copiar=this.SelectedSucursal.Id!=0?`*-${this.cotizarPack.Paquete}*\n*Sucursal:* ${this.clinica.Descripcion}\n*Horario:*\n ${this.clinica.horario[0]}\n*Horario de Laboratorio:*\n${this.clinica.horario.length>1?this.clinica.horario[1]:'7am a 12pm'}\n*Preparación:* \n${preparaciones}\n*SubEstudios:*\n${subEstudioPack}\n*Precio:* $${this.cotizarPack.PrecioPaquete} 💰`:`*-${this.cotizarPack.Paquete}*\n*Preparación:* \n${preparaciones}\n*SubEstudios:*\n${subEstudioPack}\n*Precio:* $${this.cotizarPack.PrecioPaquete} 💰`
                
            }
            else{
                this.Copiar=this.SelectedSucursal.Id!=0?'*Sucursal:* '+this.clinica.Descripcion+'\n*Horario:* '+this.clinica.horario[0]+'\n*Horario de Laboratorio:* '+(this.clinica.horario.length>1?this.clinica.horario[1]:'7am a 12pm')+'\n':'_Cotización de Estudio_'
                if (this.lista_cotizar.length!=1){
                    this.Copiar+= '\n ----------------------------------------------------------------- \n*Total de cotización:* $'+this.cotizar_total +'\n ----------------------------------------------------------------- \n'
                }
                this.lista_cotizar.map(item=>{
                    let preparaciones = ''
                    let subEstudioPack = ''
                    if(item.Preparacion!=null){
                        for (let x = 0; x < item.Preparacion.length; x++){
                            preparaciones += item.Preparacion[x] + '\n'
                        }   
                    }
                    if(item.SubEstudio!=null){
                        for(let y = 0; y < item.SubEstudio.length; y++){ 
                            subEstudioPack += item.SubEstudio[y];
                            if (y === item.SubEstudio.length - 1) {
                                subEstudioPack += '.\n';
                            } else {
                                subEstudioPack += ',';
                            }

                        }   
                    }
                    this.Copiar = this.Copiar + '\n*-' + item.Descripcion +'*'+'\n*Precio:* $' + item.Precio + '💰\n' + '\n*Preparación:* \n'+ preparaciones+ (subEstudioPack!='' ? ('\n*SubEstudios:* \n' + subEstudioPack) : '')  + (item.PrecioDescuento!=null ? '' : '')
                    
                })
                /* if (this.lista_cotizar.length!=1){
                    this.Copiar+= '\n ----------------------------------------------------------------- \n*Total:* $'+this.cotizar_total+'\n*Total cita en línea:* $'+this.cotizar_totdesc
                } */
                

            }
            
            //  for (var i = 0; i < this.arregloTotal; i++) {
            //     var chatInactivo = document.getElementById(i)
            //     chatInactivo.classList.remove('chatActive')
            // }
            this.Copiar+='\n _*Nuestros precios incluyen IVA, pueden cambiar sin previo aviso._'
            navigator.clipboard.writeText(this.Copiar).then(() => {
                this.$gtag.event('cotizar',{
                    'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                    'event_label': 'Total de Cotizaciones',
                    'value': this.cotizar_totdesc 
                })
                this.$gtag.event('cotizar',{
                    'event_category': 'Por Clínica',
                    'event_label': this.SelectedSucursal.Nombre,
                    'value': this.cotizar_totdesc 
                })
                
            })
            .catch(e => {
                console.error(e)
            });    
        }
    }
}
</script>
