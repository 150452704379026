<template>
  <div class="scroll">
    <ul v-show="network=='WA'" class="nav nav-tabs row miTabs">
      <li class="nav-item col-6">
        <a class="nav-link" @click="tipo = true" :class="{ active: tipo }"
          ><span class="h6">
            <font-awesome-icon icon="file-medical-alt" /> RSV/OPC</span
          ></a
        >
      </li>
      <li class="nav-item col-6">
        <a class="nav-link" @click="consultarEXAsender()" :class="{ active: !tipo }"
          ><span class="h6"
            ><font-awesome-icon icon="glasses" /> Examen</span
          ></a
        >
      </li>
    </ul>
    <div v-if="tipo">
      <div class="RSV" :class="{ 'd-none': !this.mostrar }">
        <h5 class="text-center">Consulta resultados</h5>
        <div
          v-if="enviado"
          class="alert alert-info alert-dismissible fade show m-2 mt-3"
          role="alert"
        >
          Enviado correctamente
          <button @click="enviado = false" type="button" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p class="p-rsv">Ingresa el RSV/OPC que se requiera consultar:</p>

        <div class="input-group mb-3">
          <input
            v-model="reservacion"
            @keypress.enter="consultarRSV(reservacion)"
            type="search"
            class="form-control"
            placeholder="RSV/OPC"
            :disabled="!status"
          />
          <div class="input-group-append">
            <button
              @click="consultarRSV(reservacion)"
              class="btn btn-outline-success"
              type="button"
              :disabled="!status"
            >
              <font-awesome-icon v-if="status" icon="search" />
              <span
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
        <p
          v-if="consulta.alerta"
          v-show="consulta.alerta == 4"
          class="alert alert-danger"
        >
          Este RSV/OPC es invalido
        </p>

        <div
          v-if="consulta.alerta"
          v-show="consulta.alerta != 4"
          class="consulta mb-5 alert alert-success"
          :class="{
            'alert-danger': consulta.alerta == 3 || consulta.alerta == 5,
          }"
        >
          <p v-if="consulta.Nombre != null" class="datosPaciente">
            Nombre: <b class="text-capitalize">{{ consulta.Nombre }}</b>
          </p>
          <p v-if="consulta.Paterno != null" class="datosPaciente">
            AP: <b class="text-capitalize">{{ consulta.Paterno }}</b>
          </p>
          <p v-if="consulta.Materno != null" class="datosPaciente">
            AM: <b class="text-capitalize">{{ consulta.Materno }}</b>
          </p>
          <p v-if="consulta.Nombre != null" class="datosPaciente">
            Fecha de nacimiento: <br />
            <b>{{ consulta.FechaNacimiento }}</b> <br />
          </p>
          <p v-if="consulta.alerta == 5" class="datosPaciente mt-3">
            Bloqueo: <br />
            <b>Por convenio de empresa</b> <br />
          </p>
          <p class="datosPaciente mt-3" v-if="consulta.cifra_alerta">
            <b class="text-danger">⚠️ Con cifra de alerta</b> <br />
          </p>
          <p class="datosPaciente">Estudio(s):</p>
          <ul v-if="consulta.alerta == 1" class="estudiosPaciente">
            <b>Listo para entregar:</b>
            <li
              class="estudiosList"
              v-for="listo in consulta.estudios.listos"
              :key="listo.id"
            >
              {{ listo }}
            </li>
          </ul>
          <ul v-else-if="consulta.alerta == 2" class="estudiosPaciente">
            <b>Listo para entregar:</b>
            <li
              class="estudiosList"
              v-for="listo in consulta.estudios.listos"
              :key="listo.id"
            >
              {{ listo }}
            </li>
            <br />
            <b>Pendiente(s):</b>
            <li
              class="estudiosList"
              v-for="pendiente in consulta.estudios.pendientes"
              :key="pendiente.id"
            >
              {{ pendiente }}
            </li>
          </ul>
          <p v-else-if="consulta.alerta == 3" class="datosPaciente">
            <b>Pendiente</b>
          </p>
        </div>
        <button
          v-show="network == 'WA' || 'GOOGLE'"
          @click="revision()"
          v-if="
            (consulta.alerta == 1 || consulta.alerta == 2) &&
            rsv.substr(0, 3) != 'opc'
          "
          class="mr-1 btn btn-info"
        >
          <font-awesome-icon v-if="esperando" icon="eye" /><span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
        <button
          @click="confirmacion(reservacion)"
          class="btn btn-success float-right"
          :disabled="consulta.alerta == 3"
          :class="{ 'd-none': !listos }"
        >
          Enviar <i class="icon-sd-send"></i>
        </button>
        <a
          :href="revisar.pdf"
          :class="{ 'd-none': !descarga }"
          rel="Descargar resultado"
          class="btn btn-secondary btn-block mt-1 text-white" 
          download="" target="_blank"
          >Descargar <font-awesome-icon icon="download"
        /></a>
        <a
          v-for="img in revisar.img"
          :class="{ 'd-none': !link }"
          :href="img.url"
          :key="img.id"
          target="_blank"
          rel="Ver estudio"
          class="btn btn-secondary btn-block text-white mb-1"
          >{{ img.estudio }} <font-awesome-icon icon="eye"
        /></a>
      </div>

      <!-- confirmacion -->
      <div
        class="confirmacion"
        :class="{ 'd-none': this.mostrar && !invalido }"
      >
        <h5 class="text-info">Los datos si corresponden al paciente?</h5>
        <div class="alert alert-info mb-5">
          <p v-if="consulta.Nombre != null" class="datosPaciente">
            Nombre: <b class="text-capitalize">{{ consulta.Nombre }}</b>
          </p>
          <p v-if="consulta.Paterno != null" class="datosPaciente">
            AP: <b class="text-capitalize">{{ consulta.Paterno }}</b>
          </p>
          <p v-if="consulta.Materno != null" class="datosPaciente">
            AM: <b class="text-capitalize">{{ consulta.Materno }}</b>
          </p>
          <p v-if="consulta.Nombre != null" class="datosPaciente">
            Fecha de nacimiento: <br />
            <b>{{ consulta.FechaNacimiento }}</b>
          </p>
          <p class="datosPaciente">
            RSV/OPC: <br />
            <b>{{ rsv }}</b>
          </p>

          <button @click="cancelar" class="btn btn-danger" :disabled="!status">
            NO
          </button>
          <!-- si es rsv -->
          <button
            v-show="rsv.substr(0, 3) != 'opc'"
            @click="enviar(rsv)"
            class="btn btn-success float-right"
            :disabled="!status"
          >
            SI
            <span
              v-if="!status"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
          <!-- si es opc -->
          <button
            v-show="rsv.substr(0, 3) == 'opc'"
            @click="enviarOPC(rsv)"
            class="btn btn-success float-right"
            :disabled="!status"
          >
            YES
            <span
              v-if="!status"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
        <div
          v-if="network == 'WA'|| 'GOOGLE'"
          class="alert alert-success"
          v-show="rsv.substr(0, 3) != 'opc'"
        >
          Enviar Resultados por correo:
          <div
            v-if="success.enviado"
            class="
              alert alert-info alert-dismissible
              fade
              show
              m-0
              mt-1
              p-2
              mb-1
            "
            role="alert"
          >
            Enviado al correo correctamente
            <button
              @click="success.enviado = false"
              type="button"
              class="close p-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            v-if="!success.correo"
            class="
              alert alert-danger alert-dismissible
              fade
              show
              m-0
              mt-1
              p-2
              mb-1
            "
            role="alert"
          >
            Favor de escribir correctamente el correo electrónico
            <button
              @click="success.correo = true"
              type="button"
              class="close p-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            v-if="!correoEnviado"
            class="
              alert alert-danger alert-dismissible
              fade
              show
              m-0
              mt-1
              p-2
              mb-1
            "
            role="alert"
          >
            No se pudo enviar el correo
            <button
              @click="correoEnviado = true"
              type="button"
              class="close p-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            v-if="clinica"
            class="
              alert alert-danger alert-dismissible
              fade
              show
              m-0
              mt-1
              p-2
              mb-1
            "
            role="alert"
          >
            Éste estudio unicamente se puede recoger en clínica.
            <button @click="clinica = false" type="button" class="close p-1">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <input
            v-model="email"
            @keypress.enter="consultarRSV(reservacion)"
            class="form-control"
            placeholder="Email"
            type="email"
          />
          <button
            @click="correo()"
            class="btn btn-success mt-1"
            :disabled="!status"
          >
            <span
              v-if="!status"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span
            ><span v-else>Enviar</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <h5 class="text-center">Examen de la Vista</h5>
      
      <p class="p-rsv">Ingresa el folio que se requiera consultar:</p>

      <div class="input-group mb-3">
        <input
          v-model="folio"
          @keypress.enter="consultarExamen()"
          type="text"
          class="form-control"
          placeholder="EXA"
          :disabled="!status"
        />
        <div class="input-group-append">
          <button
            @click="consultarExamen()"
            class="btn btn-outline-success"
            type="button"
            :disabled="!status"
          >
            <font-awesome-icon v-if="status" icon="search" />
            <span
              v-else
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
      <div
        v-if="consulta!=[]"
        class="consulta mb-5 alert alert-info pa-0"
      >
        <b>Examenes listos para enviar:</b>
        <div v-if="consulta.status==1" class="d-flex flex-column">
          <a class="btn btn-primary btn-sm" :href="consulta.pdf">Descargar <font-awesome-icon icon="download" /></a>
          <button class="btn btn-success btn-sm mt-1" @click="enviarEXA(0)">Enviar <i class="icon-sd-send"></i></button>
        </div>
        <div v-else class="card text-left pa-1 mt-1" v-for="(c, index) in consulta" :key="index">
          <p v-if="c.nombre != null" class="datosPaciente">
            <span v-if="c.nombre != 'EXAMEN VISTA'">Nombre: <b class="text-capitalize">{{ c.nombre }}</b><br></span>
            <span v-if="c.paterno != 'EXAMEN VISTA'">AP: <b class="text-capitalize">{{ c.paterno }}</b><br></span>
            <span v-if="c.materno != null && c.materno!='EXAMEN VISTA'">AM: <b class="text-capitalize">{{ c.materno }}</b><br></span>
            <span v-if="c.fecha_nacimiento != '1900-01-01'">Fecha de nacimiento:<b>{{ c.fecha_nacimiento }}</b> <br></span>
            <span v-if="c.folio != null">Folio: <b>{{ c.folio }}</b><br></span>
            <span v-if="c.fecha_examen != null && c.fecha_examen!='1900-01-01'">Fecha: <b>{{ c.fecha_examen.substr(0,10) }}</b><br></span>
          </p>
          <button class="btn btn-success btn-sm" @click="enviarEXA(c.folio)">Enviar <i class="icon-sd-send"></i></button>
        </div>
        
      </div>
      <div v-else class="alert alert-seccondary">
        <b>Sin resultados</b>
      </div>
    </div>
  </div>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faShare,
  faEye,
  faDownload,
  faGlasses,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faShare, faEye, faDownload, faGlasses);
import asesorService from "../../services/asesor";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/index.css";
import Vue from "vue";
Vue.use(VueToast);
export default {
  name: "Resultados",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      reservacion: "",
      res: 4,
      errors: [],
      invalido: false,
      status: true,
      rsv: "",
      enviado: false,
      listos: false,
      consulta: [
        {
          Estatus: null,
          Nombre: "jose emmanuel",
          Paterno: "lopez",
          Materno: "loera",
          FechaNacimiento: "1996-02-14T00:00:00",
          alerta: 0,
        },
      ],
      mostrar: true,
      revisar: { pdf: null, img: [{ estudio: null, url: null }] },
      descarga: false,
      link: false,
      esperando: true,
      email: "",
      success: { correo: true, enviado: false, mensaje: null },
      correoEnviado: true,
      clinica: false,
      network: null,
      tipo: true,
      folio: "",
    };
  },
  created() {
    this.network=localStorage.getItem('Network')
    this.$bus.$off("limpiarRSV");
    this.$bus.$on("limpiarRSV", () => {
      this.limpiar();
    });
  },
  methods: {
    networkActiva() {
      this.network = asesorService.getNetwork();
    },
    confirmacion() {
      this.mostrar = false;
    },
    consultarRSV(rsv) {
      if (this.reservacion != "") {
        Vue.$toast.open({
          message: "Se está buscando el resultado...",
          position: "top-right",
          type: "default",
          duration: 5000,
        });
        this.rsv = rsv.toLowerCase();
        // this.consulta.alerta=5
        this.status = false;
        this.listos = false;
        this.descarga = false;
        this.link = false;
        asesorService
          .consultarRSV(rsv)
          .then((resp) => {
            var moment = require("moment");
            moment.locale("es");
            resp.FechaNacimiento = moment(resp.FechaNacimiento).format("ll");

            this.consulta = resp;
            this.status = true;
            if (this.consulta.listos == null) {
              this.listos = true;
              this.networkActiva();
            } else {
              this.listos = false;
            }
          })
          .catch((err) => {
            Vue.$toast.open({
              message: err,
              position: "top-right",
              type: "error",
            });
            this.status = true;
            return err;
          });
      }
    },
    enviar(rsv) {
      this.status = false;
      asesorService.enviarRSV(rsv).then((r) => {
        this.enviado = r;
        this.mostrar = true;
        this.status = true;
        this.$gtag.event("resultadosEnviados", {
          event_category: JSON.parse(sessionStorage.getItem("sesion")).nombre,
          event_label: "Total RSV",
          value: 1,
        });
      });
    },
    enviarOPC() {
      this.status = false;
      let sender_actual = JSON.parse(sessionStorage.getItem("sender_actual"));
      asesorService
        .sendMensaje(
          sender_actual,
          "Tus lentes ya están listos y esperándote en clínica 🤗"
        )
        .then(() => {
          this.$bus.$emit("bajarScroll", 1);
          this.mostrar = true;
          this.status = true;
          this.$gtag.event("resultadosEnviados", {
            event_category: JSON.parse(sessionStorage.getItem("sesion")).nombre,
            event_label: "Total OPC",
            value: 1,
          });
        });
    },
    cancelar() {
      this.mostrar = true;
    },
    limpiar() {
      this.reservacion = "";
      this.consulta.Estatus = false;
      this.mostrar = true;
      this.enviado = false;
      this.consulta = [
        {
          Estatus: null,
          Nombre: "jose emmanuel",
          Paterno: "lopez",
          Materno: "loera",
          FechaNacimiento: "1996-02-14T00:00:00",
          alerta: 0,
        },
      ];
      this.listos = false;
      this.status = true;
      this.descarga = false;
      this.link = false;
      this.email = "";
      this.correoEnviado = true;
      this.clinica = false;
      this.networkActiva();
      this.tipo=true
      this.folio=null
    },
    revision() {
      this.descarga = false;
      this.link = false;
      this.esperando = false;
      asesorService.imgLink().then((img) => {
        this.revisar = img;
        this.esperando = true;
        if (this.revisar.pdf != "") {
          this.descarga = true;
        }
        if (this.revisar.img[0].url != "") {
          this.link = true;
        }
      });
    },
    correo() {
      this.status = false;
      if (this.email != "") {
        asesorService.rsvCorreo(this.email).then((axel) => {
          this.success = axel;
          this.status = true;
          if (this.success.enviado == false) {
            this.correoEnviado = false;

            if (this.success.mensaje == "Recoger resultados en clínica") {
              this.clinica = true;
            }
          }
        });
      }
    },
    consultarEXAsender(){
      this.limpiar()
      let senderid = sessionStorage.getItem('sender_actual')
      this.tipo = false;
      Vue.$toast.open({
          message: "Se estan buscando coincidencias con éste numero...",
          position: "top-right",
          type: "default",
          duration: 5000,
        });
      asesorService.consultarExamen(senderid).then((resp) => {
            var moment = require("moment");
            moment.locale("es");
            this.consulta = resp;
            
          })
          .catch((err) => {
            Vue.$toast.open({
              message: 'No se encontraron Examenes de la vista en este numero, intenta probar poniendo el folio.',
              position: "top-right",
              type: "error",
            });
            this.status = true;
            this.consulta=null
            return err;
          });
    },
    consultarExamen() {
      console.log(this.folio)
      if (this.folio != "") {
        if(this.folio.substring(0,3)=='EXA'||this.folio.substring(0,3)=='exa'){
          this.folio=this.folio.slice(3)
          console.log(this.folio)
        }  
        Vue.$toast.open({
          message: "Se está buscando el examen...",
          position: "top-right",
          type: "default",
          duration: 5000,
        });
        // this.consulta.alerta=5
        this.status = false;
        asesorService.buscarExamen(this.folio).then((resp) => {

            this.consulta = resp.data;
            this.status = true;
          })
          .catch((e) => {
            console.log(e.status)
            if(e.status>399){
              this.consulta=false
              this.status =true
            Vue.$toast.open({
              message: 'No se encontró examen con éste folio',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            }
            //this.limpiar()
          }).finally(()=>{
            this.status =true
          })
      }
    },
    enviarEXA(folio) {
      this.status = false;
      asesorService.enviarExamen(folio==0?this.folio:folio).then((r) => {
        this.enviado = r;
        this.status = true;
        this.$gtag.event("resultadosEnviados", {
          event_category: JSON.parse(sessionStorage.getItem("sesion")).nombre,
          event_label: "Total EXA",
          value: 1,
        });
      });
    },
  },
};
</script>