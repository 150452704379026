<template>
  <div class="scroll px-2 mt-2">
    <h5 class="text-center">Pacientes Finalizados</h5>
    <p class="text-center">Últimos 20 pacientes finalizados</p>

      <div class="mt-3 mb-3" v-if="numeroPaciente !== null">
        <h5 class="text-center mb-1">Paciente seleccionado:</h5>
        <p class="text-center font-size-lg-custom"><strong>{{ numeroPaciente }}</strong></p>
    </div>

    <div v-if="sucursales && sucursales.length > 0">
      <div
        class="mb-3 mt-3 text-center"
        v-for="sucursal in sucursales"
        :key="sucursal.id"
      >
        <p class="mb-1">
          <strong>Telefono: </strong
          ><span class="mt-0 mb-0">{{ sucursal.telefono }}</span>
        </p>
        <p class="mb-2">
          <strong>Fecha: </strong
          ><span class="mt-0 mb-0">{{ sucursal.createdAt }}</span>
        </p>

        <div class="d-flex justify-content-center  mb-2">
          <button
            @click="verHistorial(sucursal.telefono)"
            type="button"
            class="btn btn-info mt-0"
          >
            Ver Historial
          </button>
        </div>


        <div class="d-flex justify-content-center">
          <button
            @click="regresarPaciente(sucursal.telefono)"
            type="button"
            class="btn btn-primary mt-0"
          >
            Regresar paciente
          </button>
        </div>
        <div class="border-top my-3"></div>
      </div>
    </div>
    <p v-else>No se encontraron pacientes finalizados.</p>
    <p v-if="!status">Cargando...</p>
  </div>
</template>

<style>
.custom-toast {
    background-color: #81f04e; /* Color de fondo personalizado */
    color: #ffffff; /* Color del texto */
}
.font-size-lg-custom {
    font-size: 18px; /* Tamaño de fuente grande */
}

</style>

<script>
import asesorService from "../../services/asesor.js";
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/index.css'
import Vue from 'vue'
Vue.use(VueToast)


export default {
  name: "PacientesFinalizados",
  data() {
    return {
      buscar: "",
      status: true,
      sucursales: [], // Inicializar como arreglo vacío
      numeroPaciente: null
    };
  },
  created() {
    this.cargarPacientesFinalizados();
  },
  methods: {
      cargarPacientesFinalizados() {
      this.status = false;
      asesorService
        .getPacientesFinalizados()
        .then((resp) => {
          console.log(resp); 
          this.sucursales = resp; 
          this.status = true;
        })
        .catch((err) => {
          console.error("Error al cargar pacientes finalizados:", err); 
          this.status = true;
        });
    },

    regresarPaciente(telefonoPaciente) {
      console.log(telefonoPaciente);
      asesorService
        .regresarPaciente(telefonoPaciente)
        .then((pacienteEstatus) => {
          if (!pacienteEstatus) {
            console.log("EL PACIENTE YA TIENE UN ASESOR ASIGNADO");
            Vue.$toast.open({
                message: 'La conversacion ya tiene un asesor asignado',
                position: 'bottom-right',
                type: 'error',
                duration: 3000
            });
          }
          else {
            sessionStorage.setItem('sender_actual', '')
            Vue.$toast.open({
                message: 'La conversacion fue restaurada con exito',
                position: 'bottom-right',
                type: 'success',
                duration: 3000
            });
          }
        })
        .catch((err) => {
          console.error("Error al regresar paciente:", err); // Manejo de errores
        });
      },

    verHistorial(telefono){
        sessionStorage.setItem('sender_actual',telefono)
        this.$bus.$emit('paciente')
        this.$bus.$emit('ver-mensajes', telefono)
        this.$bus.$emit('chatAbierto', true)
        this.numeroPaciente = telefono;
    }
  },
};
</script>
